import React from "react";
import { useLocation } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { Rating } from "react-simple-star-rating";
import Helper from "../../utillity/helper";

export const ProductInfo = () => {
	const state = useLocation().state;

	console.log("product Info", state.product);
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: { xs: "column", md: "row" },
				width: "100%",
				height: "100%",
				p: 2,
			}}
		>
			<Box
				sx={{
					width: { xs: "100%", md: "45%" },
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					alignContent: "center",
				}}
			>
				<Box
					component="img"
					src={state.product.image}
					sx={{ width: "250px", height: "250px", p: 2 }}
					alt="product image"
				/>
			</Box>
			<Box
				sx={{
					width: { xs: "100%", md: "55%" },
					mx: { xs: 0, md: 4 },
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					// alignItems: "center",
					// alignContent: "center",
				}}
			>
				<Typography fontSize={22} fontWeight={600} color="initial">
					{state.product.title}
				</Typography>
				{/* <Typography fontSize={22} fontWeight={600} color="initial">
					{state.product.rating}
				</Typography> */}
				<Box sx={{ py: 1 }}>
					<Rating initialValue={state.product.rating} />
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
					}}
				>
					<Typography
						fontSize={20}
						fontWeight={600}
						color="initial"
						padding={0.5}
					>
						Product income:
					</Typography>
					<Typography
						fontSize={18}
						fontWeight={500}
						color="initial"
						alignContent={"center"}
						alignItems={"center"}
						padding={"7px"}
					>
						{state.product.price_symbol +
							" " +
							Helper.numberFormat(
								typeof state.product.annualPrice === "string"
									? Number(state.product.annualPrice.replace(/\,/g, ""))
									: state.product.annualPrice
							)}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
					}}
				>
					<Typography
						fontSize={20}
						fontWeight={600}
						color="initial"
						padding={0.5}
					>
						Retail Price:
					</Typography>
					<Typography
						fontSize={18}
						fontWeight={500}
						color="initial"
						alignContent={"center"}
						alignItems={"center"}
						padding={"7px"}
					>
						{state.product.price_symbol + " " + state.product.price_value}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
					}}
				>
					<Typography
						fontSize={20}
						fontWeight={600}
						color="initial"
						padding={0.5}
					>
						Ratings:
					</Typography>
					<Typography
						fontSize={18}
						fontWeight={500}
						color="initial"
						alignContent={"center"}
						alignItems={"center"}
						padding={"7px"}
					>
						{state.product.rating}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
					}}
				>
					<Typography
						fontSize={20}
						fontWeight={600}
						color="initial"
						padding={0.5}
					>
						Total Reviews:
					</Typography>
					<Typography
						fontSize={18}
						fontWeight={500}
						color="initial"
						alignContent={"center"}
						alignItems={"center"}
						padding={"7px"}
					>
						{state.product.ratings_total}
					</Typography>
				</Box>

				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
					}}
				>
					<Typography
						fontSize={20}
						fontWeight={600}
						color="initial"
						padding={0.5}
					>
						Amazon ASIN:
					</Typography>
					<Typography
						fontSize={18}
						fontWeight={500}
						color="initial"
						alignContent={"center"}
						alignItems={"center"}
						padding={"7px"}
					>
						{state.product.asin}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
					}}
				>
					<Typography
						fontSize={20}
						fontWeight={600}
						color="initial"
						padding={0.5}
					>
						Category:
					</Typography>
					<Typography
						fontSize={18}
						fontWeight={500}
						color="initial"
						alignContent={"center"}
						alignItems={"center"}
						padding={"7px"}
					>
						{state.product.category_id}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
					}}
				>
					<Typography
						fontSize={18}
						fontWeight={500}
						color="initial"
						alignContent={"center"}
						alignItems={"center"}
						padding={"7px"}
					>
						{state.product.delivery_tagline}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
					}}
				>
					<Typography
						fontSize={20}
						fontWeight={600}
						color="initial"
						padding={0.5}
					>
						Delivery Information:
					</Typography>
					<Typography
						fontSize={18}
						fontWeight={500}
						color="initial"
						alignContent={"center"}
						alignItems={"center"}
						padding={"7px"}
					>
						{state.product.delivery_tagline_price_is_free === 1
							? "free"
							: "FREE Shipping on orders over $25 shipped by Amazon "}
					</Typography>
				</Box>

				{state.product.is_prime ? (
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<Typography
							fontSize={20}
							fontWeight={600}
							color="initial"
							padding={0.5}
						>
							Prime Eligible:
						</Typography>
						<Typography
							fontSize={18}
							fontWeight={500}
							color="initial"
							alignContent={"center"}
							alignItems={"center"}
							padding={"7px"}
						>
							Yes
						</Typography>
					</Box>
				) : null}
			</Box>
		</Box>
	);
};
